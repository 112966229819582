// import { format } from 'date-fns';
import React, { useCallback, useState, useEffect } from "react";
import { get, map } from "lodash";
import moment from "moment";
import { getBountiesAPI } from "../../../../services/page.service";
import CraToken from "assets/icons/cra-token.svg";
import CramToken from "assets/icons/cram-token.svg";
import TusToken from "assets/icons/tus-token.svg";
import { ModalThumbnail } from "./ModalImage";
import { Card } from "./Card";

const PAGE_SIZE = 6;

export const PostList = () => {
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [posts, setPosts] = useState([]);
  const [metaData, setMetaData] = useState();
  const [idBounty, setIdBounty] = useState<number>();
  const [imgBounty, setImgBounty] = useState<string>();
  const [desBounty, setDesBounty] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [startDate, setStartDate] = useState<string>();

  const animAOS = {
    fadeUp: `fade-up`,
    slideUp: `slide-up`,
    fade: `fade`,
    zoomOutUp: `zoom-out-up`,
    time1: 1000,
    time15: 1500,
  };

  const fetchData = useCallback(
    async ({ page = 1 }) => {
      try {
        const res = await getBountiesAPI({ page, pageSize: PAGE_SIZE });
        const data = get(res, "content.data");
        const meta = get(res, "content.meta");
        const tmpData = [...posts, ...data];
        setPosts(tmpData);
        setMetaData(get(meta, "pagination"));
      } catch (error) { }
    },
    [posts]
  );

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);

  const _handleLoadMore = useCallback(() => {
    if (page >= get(metaData, "pageCount")) {
      return;
    }
    const nextpage = page + 1;
    setPage(nextpage);
    fetchData({ page: nextpage });
  }, [page, metaData]);

  if (!posts) {
    return (
      <div>
        <div
          className="bg-black opacity-70 fixed top-0 bottom-0 left-0 right-0 transition-all"
          style={{
            zIndex: 9991,
          }}
        />
        <div
          className="loadesh flex flex-col justify-center items-center relative"
          style={{
            zIndex: 9992,
          }}
        >
          <div className="loadesh-circle">
            <div className="loadesh-waves" />
          </div>
          <span className="relative">Loading...</span>
        </div>
      </div>
    );
  }

  const handleOpen = (id, img, endDate, startDate) => {
    setIdBounty(id);
    setImgBounty(img);
    // setDesBounty(desBounty);
    setEndDate(endDate);
    setStartDate(startDate);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        className="bounty-list slide-to-top"
      // data-aos={animAOS.slideUp}
      // data-aos-duration={animAOS.time1}
      >
        <div className="bounty-rope" />
        <div className="bounty-content py-12 sm:py-24 md:pb-25 px-2 sm:px-6 mx-auto">
          <div
            className="
              post-list 
              grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 
              gap-x-6 gap-y-8
            "
            style={{
              minHeight: 600,
            }}
          >
            {posts && Object.keys(posts).length > 0 ? (
              map(posts, (item, index) => {
                return (
                  <Card
                    key={item.id}
                    item={item}
                    index={index}
                    handleOpen={handleOpen}
                  />
                );
              })
            ) : (
              <div className="col-span-1 lg:col-span-2 xl:col-span-3 text-center items-center text-gray-800 text-2xl font-bold">
                No bounties posted. Check back again.
              </div>
            )}
          </div>

          {posts && posts.length >= PAGE_SIZE && posts.length == PAGE_SIZE && (
            <div
              onClick={_handleLoadMore}
              className="bounty-loadmore flex items-center justify-center mt-8 w-fit px-8 py-1 mx-auto cursor-pointer relative"
            >
              Load More
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <ModalThumbnail
          show={showModal}
          handleClose={handleClose}
          id={idBounty}
          img={imgBounty}
          endDate={endDate}
          startDate={startDate}
        />
      )}
    </>
  );
};
