import { IconSearch } from "components/atoms/iconSearch";
import { format, fromUnixTime } from "date-fns";
import { findIndex, get, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  getCurrentLeaderBoard,
  getMonth,
  getWeek,
  getYear,
} from "services/page.service";
import { Board } from "./Board";
import { DiamondBoard } from "./DiamondBoard";
import { MarketBoard } from "./MarketBoard";
import top100Arena from "assets/data/top100-arena.json";
import top100Diamond from "assets/data/top100-diamond.json";
import top100Market from "assets/data/top100-market.json";
import allBattleUserInfo from "assets/data/all-user-battle-info.json";
import allMarketUserInfo from "assets/data/all-user-market.json";

export const Arena = () => {
  const [weekIdCurrent, setWeekIdCurrent] = useState<number>(null);
  const [monthIdCurrent, setMonthIdCurrent] = useState<{
    month: number;
    year: number;
  }>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const [yearIdCurrent, setYearIdCurrent] = useState<number>(
    new Date().getFullYear()
  );

  const [dataFilter, setDataFilter] = useState<{
    week: any[];
    month: any[];
    year: any[];
  }>({
    week: null,
    month: null,
    year: null,
  });
  const [emailSearch, setEmail] = useState("");

  const [listRecordWeek, setListRecordWeek] = useState<any[]>([]);
  const [listRecordMonth, setListRecordMonth] = useState<any[]>([]);
  const [listRecordYear, setListRecordYear] = useState<any[]>([]);

  const fetchData = useCallback(async () => {
    try {
      setListRecordWeek(top100Arena);
      setListRecordMonth(top100Diamond);
      setListRecordYear(top100Market);
    } catch (error) { }
  }, []);

  const fetchDataWithEmail = useCallback(
    async (email) => {
      let arenaScore = allBattleUserInfo[email]?.sort((n1, n2)=> n2.score - n1.score) || [];
      let diamondSpent = allBattleUserInfo[email]?.sort((n1, n2)=> n2.diamons - n1.diamons) || [];
      let marketUser = allMarketUserInfo[email] || [];

      try {
        setDataFilter({
          week: arenaScore,
          month: diamondSpent,
          year: marketUser,
        });
      } catch (error) { }
    },
    [monthIdCurrent, yearIdCurrent, weekIdCurrent]
  );

  const _onChangeEmail = (e) => {
    setEmail(e.target.value);

    if ((e.target && e.target.value && e.target.value.length !== 42) || e.target.value === "") {
      setDataFilter({
        week: null,
        month: null,
        year: null,
      });

      return;
    }

    let valid = e.target.value.toLowerCase();
  
    if (valid) {
      fetchDataWithEmail(valid);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="arena text-black">

        <div className="relative flex items-center w-full md:w-1/2 mt-8 mb-8 mx-auto">
          <input
            value={emailSearch}
            onChange={_onChangeEmail}
            type="search"
            className="py-2 px-3 w-full h-10 flex items-center rounded-md"
            placeholder="Please enter your Account Address (C-Chain)..."
          />
          <IconSearch />
        </div>

        <div className="flex flex-col md:grid grid-cols-3 gap-6 md:gap-2 lg:gap-6 w-full">
          {/* <Board
            totalRecord={listIDS?.week_ids?.length || 0}
            listData={listRecordWeek}
            boardType="Arena"
            time={
              !isEmpty(listRecordWeek)
                ? `${format(
                  fromUnixTime(Number(get(listRecordWeek, "[0].start_time"))),
                  "dd/MM/yyyy"
                )} - ${format(
                  fromUnixTime(Number(get(listRecordWeek, "[0].end_time"))),
                  "dd/MM/yyyy"
                )}`
                : ""
            }
            dataFilter={dataFilter?.week}
            isHiddenLeft={
              weekIdCurrent === get(listIDS, `week_ids[${lengthWeeks - 1}]`)
            }
            isHiddenRight={weekIdCurrent === get(listIDS, "week_ids[0]")}
          />
          <Board
            totalRecord={listIDS?.month_ids?.length || 0}
            listData={listRecordMonth}
            boardType={"Hand of Midas"}
            time={"All time"}
            dataFilter={dataFilter?.month}
            isHiddenLeft={
              monthIdCurrent?.month ===
              get(listIDS, `month_ids[${lengthMonth - 1}].month_id`)
            }
            isHiddenRight={
              monthIdCurrent?.month === get(listIDS, "month_ids[0].month_id")
            }
          />
          <Board
            totalRecord={listIDS?.year_ids?.length || 0}
            listData={listRecordYear}
            boardType="Trade master"
            time={"All time"}
            dataFilter={dataFilter?.year}
            isHiddenLeft={
              yearIdCurrent === get(listIDS, `year_ids[${lengthYear - 1}]`)
            }
            isHiddenRight={yearIdCurrent === get(listIDS, "year_ids[0]")}
          /> */}

          <Board
            listData={listRecordWeek}
            boardType={"Arena"}
            boardBy={"Arena Point"}
            time={"All time"}
            dataFilter={dataFilter?.week}
          />

          <DiamondBoard
            listData={listRecordMonth}
            boardType={"Hand of Midas"}
            boardBy={"USD Spent"}
            time={"All time"}
            dataFilter={dataFilter?.month}
          />

          <MarketBoard
            listData={listRecordYear}
            boardType={"Trade Master"}
            boardBy={"Buy/Sell Crabada"}
            time={"All time"}
            dataFilter={dataFilter?.year}
          />

        </div>
      </div>
    </>
  );
};
