import React, { useCallback, useState, useEffect } from "react";

import { getBannerAPI } from "../../../../services/page.service";
import { get } from "lodash";
import PlayVideo from "components/Video";

export const Banner = () => {
  const [banner, setBanner] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const res = await getBannerAPI();
      const data = get(res, "content.data");
      setBanner(data);
    } catch (error) { }
  }, [banner]);

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div
      className="bounty-banner w-full flex flex-col sm:flex-row items-center justify-center relative box"
    >
      <div className="hidden sm:block">
        <PlayVideo
          video={`/videos/bounty_video.webm`}
        />
      </div>
      <img className="banner-img block sm:hidden" src={`${get(banner, "[0].attributes.cover.data.attributes.url")}`} alt="" />
      <div className="content flex flex-col items-center justify-center px-4 md:px-0 relative sm:absolute">
        <div className="title font-bold">
          {get(banner, "[0].attributes.title")}
        </div>
        <div
          className="description text-center"
          dangerouslySetInnerHTML={{
            __html: get(banner, "[0].attributes.description"),
          }}
        />
      </div>
    </div>
  );
};
