import IconNext from "assets/icons/icon_next.svg";
import IconPrev from "assets/icons/icon_prev.svg";
import IconRankA from "assets/icons/icon_rank_a.svg";
import IconRankB from "assets/icons/icon_rank_b.svg";
import IconRankC from "assets/icons/icon_rank_c.svg";
import IconReward from "assets/icons/icon_reward.svg";
import IconCrab from "assets/icons/icon_crab.svg";
import IconBoard from "assets/icons/icon_board.svg";
import { map } from "lodash";
import React, { useState } from "react";
import { ModalArenaReward } from "./ModalArenaReward";
import './board.scss';

interface Props {
  listData: any[];
  boardType: any;
  boardBy: any;
  time: any;
  dataFilter: any;
}

export const Board: React.FunctionComponent<Props> = ({
  boardType,
  boardBy,
  time,
  listData,
  dataFilter,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const logoRank = {
    1: IconRankA,
    2: IconRankB,
    3: IconRankC,
  };

  const renderRankIcon = (rank: number) => {
    return rank < 4 ? (
      <div className="flex items-center justify-center relative">
        <img
          src={logoRank[rank]}
          alt=""
          style={{ minWidth: 36, minHeight: 36, width: 36, height: 36 }}
        />
        <span
          className="absolute left-0 right-0 text-center mt-1 items-center justify-center"
          style={{ color: "#000000" }}
        >
          {rank}
        </span>
      </div>
    ) : (
      <div
        className="flex items-center justify-center"
        style={{ minWidth: 36, minHeight: 36, width: 36, height: 36 }}
      >
        <span className="tex-center">{rank}</span>
      </div>
    );
  };

  const classnames = {
    // parent: 'md:grid grid-cols-5 gap-2',
    parent: 'md:flex md:flex-row space-between gap-2',
    child1: '',
    child2: '',
    child3: 'text-right md:justify-end'
  };

  // Crabada(s) nodes & mode
  const renderCheckNode = (item: any, index?: any) => {
    let nodeType = "";
    let nodeBg = "";

    const Node = [
      { type: "Normal", bg: "#4295E4" },
      { type: "Hard", bg: "#D33B3F" },
      { type: "Hell", bg: "#A32FB9" }
    ]

    if (item > 100) {
      nodeType = Node[1].type
      nodeBg = Node[1].bg
    } else if (item > 200) {
      nodeType = Node[2].type
      nodeBg = Node[2].bg
    } else {
      nodeType = Node[0].type
      nodeBg = Node[0].bg
    }

    const classie = item + nodeType;

    return (
      <>
        <img src={IconBoard} alt="" />
        <span
          style={{ backgroundColor: nodeBg }}
          className={classie}
        >
          {nodeType}:&nbsp;
          {item}
        </span>
      </>
    )
  }

  return (
    <div className="leaderboard-board">
      <div className="flex items-center pt-3 px-4">
        <button
          className="relative items-center px-2 font-bold text-base h-8 hidden"
          style={{
            backgroundColor: "#8D4247",
            color: "#EDD0A7",
            boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.1)",
            borderRadius: 4,
          }}
          onClick={handleOpen}
        >
          <img src={IconReward} className="w-6 h-6" alt="" />
          <span className="block ml-1">Reward</span>
        </button>
      </div>

      {/* Button */}
      <div className="flex flex-col items-center justify-center mt-2">
        <div className="font-bold" style={{ fontSize: 32 }}>
          {boardType}
        </div>
        <div className={`flex items-center gap-2`} style={{ height: 24 }}>
          <div
            className="text-base mt-1 text-center overflow-hidden"
            style={{ minWidth: 140 }}
          >
            <div>{time}</div>
          </div>
        </div>
      </div>

      {/* Show data */}
      <div
        className="arena-list mt-6"
      >
        <div className={`row head hidden items-center mb-2 px-2 pr-4 ${classnames.parent}`}>
          <div className={`header ${classnames.child1}`}>Ranking</div>
          <div className={`header ${classnames.child2}`}>Player</div>
          <div className={`header ${classnames.child3}`}>{boardBy}</div>
        </div>

        <div
          className="scroll-smooth overflow-x-hidden overflow-y-auto scroll-custom overflow-auto"
          style={
            listData.length >= 10
              ? {
                height: (88 * 5),
              }
              : {}
          }
        >

          {dataFilter && map(dataFilter, (item) => {
            return (
              <div
                className={`row each flex flex-col px-6 md:px-2 py-4 md:py-2 ${classnames.parent} ${item?.user_id == 1 && ""}`}
                key={item.full_name}
              >
                <div className={`flex items-center justify-between md:justify-center w-full md:w-auto ${classnames.child1}`}>
                  <div className="header flex md:hidden">Ranking</div>
                  <div className="value">{renderRankIcon(item?.arena_rank) || "--"}</div>
                </div>

                <div className={`flex items-center justify-between w-full md:w-auto ${classnames.child2}`}>
                  <div className="header flex md:hidden">Player</div>
                  <div className="value text-right md:text-left">
                    <div className="flex flex-col items-end md:items-start">
                      <div className="level leading-4 opacity-70">Level. {item.level || "--"}</div>
                      <div className="user-name text-xl leading-6 mt-1 truncate" title={item?.full_name}>{item.full_name || "--"}</div>
                    </div>

                    <div className="data flex items-center">
                      <div className="flex items-center gap-1" title="Total Crabada(s)">
                        <img src={IconCrab} alt="" />
                        <span>{item.crabs || "--"}</span>
                      </div>
                      <div className="flex items-center gap-1 ml-3" title="Node clear">
                        {renderCheckNode(item?.node) || "--"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`flex items-center justify-between w-full md:w-auto ${classnames.child3}`}>
                  <div className="header flex md:hidden">{boardBy}</div>
                  <div className="value">
                    {Number(item.score).toLocaleString("en", {
                      maximumFractionDigits: 2,
                    }) || "--"}
                  </div>
                </div>
              </div>
            );
          })}

          {!dataFilter && map(listData, (item) => {
            return (
              <div
                className={`row each flex flex-col px-6 md:px-2 py-4 md:py-2 ${classnames.parent} ${item?.user_id == 1 && ""}`}
                key={item.full_name}
              >
                <div className={`flex items-center justify-between md:justify-center w-full md:w-auto ${classnames.child1}`}>
                  <div className="header flex md:hidden">Ranking</div>
                  <div className="value">{renderRankIcon(item?.arena_rank) || "--"}</div>
                </div>

                <div className={`flex items-center justify-between w-full md:w-auto ${classnames.child2}`}>
                  <div className="header flex md:hidden">Player</div>
                  <div className="value text-right md:text-left">
                    <div className="flex flex-col items-end md:items-start">
                      <div className="level leading-4 opacity-70">Level. {item.level || "--"}</div>
                      <div className="user-name text-xl leading-6 mt-1 truncate" title={item?.full_name}>{item.full_name || "--"}</div>
                    </div>

                    <div className="data flex items-center">
                      <div className="flex items-center gap-1" title="Total Crabada(s)">
                        <img src={IconCrab} alt="" />
                        <span>{item.crabs || "--"}</span>
                      </div>
                      <div className="flex items-center gap-1 ml-3" title="Node clear">
                        {renderCheckNode(item?.node) || "--"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`flex items-center justify-between w-full md:w-auto ${classnames.child3}`}>
                  <div className="header flex md:hidden">{boardBy}</div>
                  <div className="value">
                    {Number(item.score).toLocaleString("en", {
                      maximumFractionDigits: 2,
                    }) || "--"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <ModalArenaReward
        boardType={boardType}
        show={showModal}
        handleClose={handleClose}
      />
    </div >
  );
};
