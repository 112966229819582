import IconBounty from "assets/images/leaderboard/icon_leaderboard_bounty.svg";
import IconLeaderboard from "assets/images/leaderboard/icon_leaderboard_rank.svg";
import Logo from "assets/images/leaderboard/leaderboad_logo.webp";
import Glow from "assets/images/leaderboard/leaderboad_logo_glow1.webp";
import Shiny from "assets/images/leaderboard/leaderboad_logo_glow2.webp";
import Banner from "assets/images/leaderboard/leaderboard_bg_2880x800.jpg";
import { useEffect, useState } from "react";
import { Arena } from "./components/Arena";
import { Bounties } from "./components/Bounties";
import "./leaderboard.scss";

const PageLeaderboard = (props) => {
  const [tab, setTab] = useState("arena");

  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  const handleActiveTab = (value) => {
    switch (value) {
      case "arena":
        setTab("arena");
        break;
      case "bounty":
        setTab("bounty");
        break;
      default:
        setTab("arena");
        break;
    }
  };

  const animAOS = {
    fadeUp: `fade-up`,
    slideUp: `slide-up`,
    fade: `fade`,
    zoomOutUp: `zoom-out-up`,
    zoomIn: `zoom-in`,
    time1: 1000,
    time15: 1500,
  };

  return (
    <div className="leaderboard min-h-screen pb-11 sm:pb-4">
      <div
        className="leaderboard-banner w-full bg-no-repeat bg-cover flex items-end justify-center"
        style={{ minHeight: 400, backgroundImage: `url(${Banner})` }}
      >
        <div
          className="leaderboard-banner__logo w-72 mb-12 relative flex items-center justify-center"
          data-aos={animAOS.zoomIn}
          data-aos-duration={animAOS.time1}
        >
          <img
            className="leaderboard-banner__logo--shiny absolute"
            src={Shiny}
            alt=""
          />
          <img
            className="leaderboard-banner__logo--glow w-64 absolute"
            src={Glow}
            alt=""
          />
          <img
            className="leaderboard-banner__logo--img w-64 relative"
            src={Logo}
            alt=""
          />
        </div>
      </div>

      <div className="leaderboard-content py-12 sm:py-10 md:pb-16 px-2 sm:px-6 mx-auto">
        <div className="leaderboard-tab mx-auto w-full">
          {/* <div className="leaderboard-tab__header flex items-center">
            <div
              className={`item transition-all text-black font-bold cursor-pointer flex items-center ${
                tab == "arena" && "active"
              }`}
              onClick={() => handleActiveTab("arena")}
            >
              <img src={IconLeaderboard} className={`mr-3 w-10 h-10`} alt="" />
              Top Arena
            </div>
            <div
              className={`ml-8 item transition-all text-black font-bold cursor-pointer flex items-center ${
                tab == "bounty" && "active"
              }`}
              onClick={() => handleActiveTab("bounty")}
            >
              <img src={IconBounty} className="mr-3 w-10 h-10" alt="" />
              Bounty
            </div>
          </div>

          <hr className="leaderboard-tab__linear mt-3 mb-10" /> */}

          <div className="leaderboard-tab__content">
            {tab === "arena" && <Arena />}
            {/* {tab === "bounty" && <Bounties />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLeaderboard;
