import React from "react";
import logo_footer from "assets/images/logo_footer.webp";
import logo_tele from "assets/images/logo_tele.webp";
import logo_discord from "assets/images/logo_discord.webp";
import logo_twister from "assets/images/logo_twister.webp";
import logo_medium from "assets/images/logo_medium.svg";

const Footer = () => {

  return (
    <footer>
      <div className="container">
        <div className="footer">
          <div className="footer_info" style={{ alignItems: 'center' }}>
            <img src={logo_footer} alt="Crabada" />
            <div className="flex flex-col">
              <div className="flex items-center">
                <a className="hover:underline transition-all" href="https://docs.crabada.com/welcome/terms-of-service" target="_blank">Terms of service</a>
                <a className="hover:underline transition-all ml-4" href="https://docs.crabada.com/" target="_blank">Whitepaper</a>
              </div>
            </div>
          </div>
          <ul>
            <li>
              <a href="https://t.me/playcrabada" title="Telegram" target="_blank">
                <img src={logo_tele} alt="Telegram" />
              </a>
            </li>
            <li>
              <a href="http://discord.gg/PlayCrabada" title="Discord" target="_blank">
                <img src={logo_discord} alt="Discord" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/playcrabada?s=11" title="Twitter" target="_blank">
                <img src={logo_twister} alt="Twister" />
              </a>
            </li>
            <li>
              <a href="https://medium.com/@PlayCrabada" title="Medium" target="_blank">
                <img src={logo_medium} alt="Medium" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
