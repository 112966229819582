import React from "react";

import "../../../assets/styles/main.css";
import './page-landing.scss';

import Banner from "./components/Banner";
import SliderHead from "./components/SliderHead";
import World from "./components/World";
import Battle from "./components/Battle";
import Parts from "./components/Parts";
import Download from "./components/Download";
import Train from "./components/Train";
import Roadmap from "./components/Roadmap";
import Backers from "./components/Backers";
import Ocean from "./components/Ocean";
import Submenu from "components/Submenu";

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

import imgFilm1 from "assets/images/img-msg-film-1.svg";
import imgFilm2 from "assets/images/img-msg-film-2.svg";
import imgFilm3 from "assets/images/img-msg-film-3.svg";
import imgFilm4 from "assets/images/img-msg-film-4.svg";
import imgFilm5 from "assets/images/img-msg-film-5.svg";
import imgFilms from "assets/images/img-msg-films.svg";

const PageLanding = () => {
  const listlink = [
    {
      content: "Inventory",
      href: "https://element.market/account",
    },
    {
      content: "Github",
      href: "https://github.com/crabada/crabada.github.io",
    },
    {
      content: "Leaderboard",
      href: "https://www.crabada.com/leaderboard",
    }
  ]

  const listfilm = [
    { img: imgFilm1 },
    { img: imgFilm2 },
    { img: imgFilm3 },
    { img: imgFilm4 },
    { img: imgFilm5 }
  ]
  return (
    // The Final Version Crabada
    // <>
    //   <Submenu />
    //   <Banner />
    //   <SliderHead />
    //   <World />
    //   <Battle />
    //   <Parts />
    //   <Download />
    //   <Train />
    //   <Roadmap />
    //   <Backers />
    //   <Ocean />
    // </>

    // Thanks Giving
    <div className="min-h-screen pt-28 pb-12 md:pt-40 md:pb-16 px-4 md:px-6 lg:px-0 transition-all">
      <section className="msg p-0 md:p-14 lg:p-16 mx-auto" data-aos="fade-up">
        <div className="msg__content">
          Dear Crabada Community,
          <br /><br />
          We have reached a point where we must make the difficult decision to sunset operations of the Crabada Battle Game and Swimmer Network.
          <br /><br />
          Throughout the past year and a half, we’ve shared a fantastic journey, growing into a thriving community and witnessing the growth of Crabada. Your passion is what makes Crabada truly unique, and we want to thank each and everyone in our incredible #snibsnib community.
          <br /><br />
          We want to extend our appreciation to everyone who supported, partnered, and played during this journey. Your passion has inspired us, and we are immensely grateful for the chance to share experiences with all of you.
          <br /><br />
          Sincerely,<br />
          The Crabada Team
        </div>

        <div className="msg__links mt-12">
          <ul className="flex flex-row">
            {
              listlink.map((item, index) => {
                return (
                  <li key={index} className="hover:opacity-70"><a href={item.href}>{item.content}</a></li>
                )
              })
            }
          </ul>
        </div>
        <div>

        </div>
      </section>

      <section className="film mt-3 mx-auto" data-aos="slide-right">
        {/* <ul className="flex flex-row">
          {
            listfilm.map((item, index) => {
              return (
                <li key={index} className="relative rounded-lg overflow-hidden"><img alt="Crabada" src={item.img} /></li>
              )
            })
          }
        </ul> */}
        <img alt="Crabada" src={imgFilms} />
      </section>
    </div>
  )
};

export default React.memo(PageLanding);


// import React from 'react';
// import './assets/styles/main.css';
// import Header from './components/Header';
// import Banner from './components/Banner';
// import SliderHead from './components/SliderHead';
// import World from './components/World';
// import Battle from './components/Battle';
// import Parts from './components/Parts';
// import Download from './components/Download';
// import Train from './components/Train';
// import Roadmap from './components/Roadmap';
// import Backers from './components/Backers';
// import Ocean from './components/Ocean';
// import Footer from './components/Footer';
// import { motion } from 'framer-motion';
// import Submenu from './components/Submenu';

// function App() {
//   return (
  //  <motion.div initial="hidden" animate="show">
  //     <Submenu />
  //     <Header />
  //     <Banner />
  //     <SliderHead />
  //     <World />
  //     <Battle />
  //     <Parts />
  //     <Download />
  //     <Train />
  //     <Roadmap />
  //     <Backers />
  //     <Ocean />
  //     <Footer />
  //  </motion.div>
//   );
// }
// export default App;