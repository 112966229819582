import React, { Component } from 'react';
import Footer from './components/templates/layout/Footer';
import Header from './components/templates/layout/Header';
// import Submenu from './components/Submenu';
import Routers from './Routes';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

// 20220715_0940 build dev
class App extends Component {
  render() {
    return (
      <motion.div initial="hidden" animate="show">
        <Header />
        <Routers />
        <Footer />
      </motion.div>
    );
  }
}

// import React from 'react';
// import './assets/styles/main.css';
// import Header from './components/Header';
// import Banner from './components/Banner';
// import SliderHead from './components/SliderHead';
// import World from './components/World';
// import Battle from './components/Battle';
// import Parts from './components/Parts';
// import Download from './components/Download';
// import Train from './components/Train';
// import Roadmap from './components/Roadmap';
// import Backers from './components/Backers';
// import Ocean from './components/Ocean';
// import Footer from './components/Footer';
// import { motion } from 'framer-motion';
// import Submenu from './components/Submenu';

// function App() {
//   return (
//    <motion.div initial="hidden" animate="show">
//       <Submenu />
//       <Header />
//       <Banner />
//       <SliderHead />
//       <World />
//       <Battle />
//       <Parts />
//       <Download />
//       <Train />
//       <Roadmap />
//       <Backers />
//       <Ocean />
//       <Footer />
//    </motion.div>
//   );
// }
export default App;