import { Banner } from './components/Banner';
import { PostList } from './components/PostList';
import './bounty.scss';
import { useEffect } from 'react';

const PageBounty = (props) => {

  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  return (
    <div className="bounty min-h-screen pb-11 sm:pb-4">
      <Banner />
      <PostList />
    </div>
  )
}

export default PageBounty;