import React, { useState, useLayoutEffect } from "react";
import { get } from "lodash";
import { format, differenceInSeconds, fromUnixTime } from "date-fns";
import ComingSoon from "assets/images/bounty/coming_soon.png";
import Ended from "assets/images/bounty/ended.png";
import "./modal.scss";

export const ModalThumbnail = ({
  handleClose,
  show,
  id,
  img,
  endDate,
  startDate,
}) => {
  // console.log("TCL: startDate", startDate);
  // console.log("TCL: endDate", endDate);
  const [timeCountDown, setTimeCountDown] = useState("");
  const [timeStatus, setTimeStatus] = useState("");
  // console.log("TCL: timeCountDown", timeCountDown);

  useLayoutEffect(() => {
    let countdown: any | null = null;

    if (!endDate) {
      setTimeCountDown("");
      return;
    }

    countdown = setInterval(() => {
      let diff = differenceInSeconds(new Date(endDate), new Date());
      let diffStartDate = differenceInSeconds(new Date(startDate), new Date());

      if (Number(diff) > 0) {
        let diffInMinute = (diff - (diff % 60)) / 60;
        diff -= diffInMinute * 60;

        let diffInHour = (diffInMinute - (diffInMinute % 60)) / 60;
        diffInMinute -= diffInHour * 60;

        const diffInDay = (diffInHour - (diffInHour % 24)) / 24;
        diffInHour -= diffInDay * 24;

        const day =
          diffInDay > 0
            ? `${diffInDay < 10 ? `0${diffInDay}` : diffInDay}`
            : "";

        const hours =
          diffInHour > 0
            ? `${diffInHour < 10 ? `0${diffInHour}` : diffInHour}`
            : "00:";

        const minute =
          diffInMinute > 0
            ? `${diffInMinute < 10 ? `0${diffInMinute}` : diffInMinute}`
            : "00:";

        const second = diff > 0 ? `${diff < 10 ? `0${diff}` : diff}` : "00";

        const timeShowTime = `${Number(day) > 0 ? day + " days - " : ""} ${hours + ":" + minute + ":" + second
          }`;

        let timeShow = "";

        if (Number(day) >= 1 && Number(diffStartDate) > 0) {
          timeShow = "Coming Soon";
          setTimeStatus("coming_soon");
        } else {
          timeShow = timeShowTime;
          setTimeStatus("");
        }

        setTimeCountDown(timeShow);
      } else if (countdown && diff <= 0) {
        clearInterval(countdown);
        countdown = null;
        setTimeStatus("ended");
      }
    }, 1000);
    // }
    // eslint-disable-next-line consistent-return
    return () => {
      if (countdown) {
        clearInterval(countdown);
      }
    };
  }, [endDate]);

  const checkImg = () => {
    switch (timeStatus) {
      case "coming_soon":
        return ComingSoon
        break;
      case "ended":
        return Ended
        break;

      default:
        return img
        break;
    }
  }

  return (
    <div
      key={id}
      className={`
          flex justify-center items-center
          top-0 left-0 
          fixed w-full h-full ${show}
          transition-all
        `}
      style={{
        zIndex: 9999,
      }}
      data-aos={show ? "fade-in" : "fade-out"}
    >
      <div className="modal-main-overlay bg-black opacity-70 fixed top-0 bottom-0 left-0 right-0 transition-all"></div>

      <section
        className="modal-main relative transition-all"
        data-aos="zoom-in"
      >
        <div className="flex w-full items-center justify-end pt-4 pr-4">
          <span
            className=" text-white relative cursor-pointer"
            onClick={handleClose}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36 12L12 36"
                stroke="#A44747"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 12L36 36"
                stroke="#A44747"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
        <div className="modal-main__img mx-8 mb-4 mt-0 overflow-hidden">
          <img src={checkImg()} alt="" />
          <div className="modal-main__img--timer font-bold absolute bottom-2 z-10 flex justify-center items-center w-full">
            {timeCountDown || ""}
          </div>
        </div>
        {/* <div className="modal-main__description text-center px-12">
            {description}
          </div> */}
      </section>
    </div>
  );
};
