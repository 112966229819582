/* eslint-disable no-throw-literal */
import { log } from '../utils/logger';

export const axiosErrorHandler = (err: any) => {
  if (err.response) {
    const { status, data, headers } = err.response;
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // log(data);
    // log(status);
    // log(headers);

    // Not Auth
    if (status === 401) {
      if (
        window.location.pathname !== '/' &&
        window.location.pathname !== '/'
      ) {
        localStorage.clear();
        window.location.href = '/';
      }
      log(err);
      throw {
        error: err,
        errorsLNG: getErrorMessage(err.response.data),
        // errorCode: getErrorMessage(err.response.data),
      };
    }

    // API return 404 not found 403 Forbidden roles (clientType)
    if (status === 403) {
      // window.location.href = SHARED_PATHS.HOME_ROUTE;
      log(err);
      throw {
        err,
        errorsLNG: getErrorMessage({
          message: 'Your login session has been expired, please try again',
        }),
      };
    }

    // eslint-disable-next-line no-throw-literal
    throw {
      error: err,
      errorsLNG: getErrorMessage(err.response.data),
      // errorCode: getErrorMessage(err.response.data),
    };
  } else if (err.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    log(err.request);
    log(err.message);
    // window.location.href = SHARED_PATHS.NOT_FOUND_ERROR_ROUTE;
    throw {
      error: err,
      // errorsLNG: getErrorMessage(err.response.data),
      errorsLNG: 'Something went wrong',
      // errorCode: getErrorMessage(err.response.data),
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    // throw new Error('Unexpected');
    // window.location.href = SHARED_PATHS.NOT_FOUND_ERROR_ROUTE;
    log(err.message);
    throw {
      error: err,
      // errorsLNG: getErrorMessage(err.response.data),
      errorsLNG: 'Something went wrong',
      // errorCode: getErrorMessage(err.response.data),
    };
  }
};

export const getErrorMessage = (err: any) =>
  // const errorsList = err.data;
  // if (errorsList) {
  //   return errorsList.map((obj) => ({
  //     field: obj.field,
  //     message: `errors.${obj.field ? obj.field + '.' : ''}${obj.message}`,
  //   }));
  // }
  ({ field: err.data ? err.data.param : null, message: `${err.message}` });
