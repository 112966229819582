// import { format } from 'date-fns';
import React, { useState, useLayoutEffect, useEffect } from "react";
import { get, map } from "lodash";
import { format, differenceInSeconds, fromUnixTime } from "date-fns";
import moment from "moment";
import { getBountiesAPI } from "../../../../services/page.service";
import CraToken from "../../../../assets/icons/cra-token.svg";
import CramToken from "../../../../assets/icons/cram-token.svg";
import TusToken from "../../../../assets/icons/tus-token.svg";
import IconEXP from "../../../../assets/icons/exp.png";
import IconCS from "../../../../assets/icons/shell.png";
import IconCommonBox from "../../../../assets/icons/common-item-box.jpg";
import IconRareBox from "../../../../assets/icons/rare-item-box.jpg";
import IconLegendaryBox from "../../../../assets/icons/legendary-item-box.jpg";
import IconEpicBox from "../../../../assets/icons/epic-item-box.jpg";
import ReactGA from "react-ga4";
// import { useAnalyticsEventTracker } from "./eventTracker";

const PAGE_SIZE = 6;

export const Card = ({ item, index, handleOpen }) => {
  const [timeCountDown, setTimeCountDown] = useState("");

  const value = get(item, "attributes");
  const img = get(value, "thumbnail.data.attributes.url");
  const description = get(value, "description");
  const id = get(item, "id");
  const thumbnail = get(value, "cover.data[0].attributes.url");
  const endDate = get(value, "endDate");
  const startDate = get(value, "startDate");

  useLayoutEffect(() => {
    let countdown: any | null = null;

    if (!endDate) {
      setTimeCountDown("");
      return;
    }

    countdown = setInterval(() => {
      let diff = differenceInSeconds(new Date(endDate), new Date());
      let diffStartDate = differenceInSeconds(new Date(startDate), new Date());

      if (Number(diff) > 0) {
        let diffInMinute = (diff - (diff % 60)) / 60;
        diff -= diffInMinute * 60;

        let diffInHour = (diffInMinute - (diffInMinute % 60)) / 60;
        diffInMinute -= diffInHour * 60;

        const diffInDay = (diffInHour - (diffInHour % 24)) / 24;
        diffInHour -= diffInDay * 24;

        const day =
          diffInDay > 0
            ? `${diffInDay < 10 ? `0${diffInDay}` : diffInDay}`
            : "";

        const hours =
          diffInHour > 0
            ? `${diffInHour < 10 ? `0${diffInHour}` : diffInHour}`
            : "00:";

        const minute =
          diffInMinute > 0
            ? `${diffInMinute < 10 ? `0${diffInMinute}` : diffInMinute}`
            : "00:";

        const second = diff > 0 ? `${diff < 10 ? `0${diff}` : diff}` : "00";

        const timeShowTime = `${Number(day) > 0 ? day + " days - " : ""} ${hours + ":" + minute + ":" + second
          }`;

        let timeShow = "";

        if (Number(day) >= 1 && Number(diffStartDate) > 0) {
          timeShow = "Coming Soon";
        } else {
          timeShow = timeShowTime;
        }

        setTimeCountDown(timeShow);
      } else if (countdown && diff <= 0) {
        clearInterval(countdown);
        countdown = null;
      }
    }, 1000);
    // }
    // eslint-disable-next-line consistent-return
    return () => {
      if (countdown) {
        clearInterval(countdown);
      }
    };
  }, [endDate]);

  const animAOS = {
    fadeUp: `fade-up`,
    slideUp: `slide-up`,
    fade: `fade`,
    zoomOutUp: `zoom-out-up`,
    time1: 1000,
    time15: 1500,
  };

  return (
    <>
      <div
        className={`card bounty mx-auto flex flex-col items-center justify-start ${index}`}
        data-aos={animAOS.zoomOutUp}
        data-aos-duration={animAOS.time1}
      >
        <div
          className={`card__title flex flex-col justify-center text-center ${value?.slug ? `cursor-pointer` : ``
            }`}
        >
          <a href={value?.slug} target="_blank">
            {value.title}
          </a>
        </div>
        <div className="card-container w-full mt-2 flex flex-col justify-between">
          <div className="card-wanted">
            <div
              className="card-wanted__image relative flex flex-col items-center justify-center box transition-all"
              onClick={() => {
                console.log("Click!", value.title);
                // gaEventTracker({
                //   category: "Click",
                //   action: "Card",
                //   label: value?.title || "",
                // });
                ReactGA.event({
                  category: "Click",
                  action: value?.title || "",
                  label: value?.title || "",
                });
              }}
            >
              {thumbnail ? (
                <div
                  className={`img overflow-hidden ${thumbnail && "box-shadow cursor-zoom-in"
                    } ${index}`}
                  style={{
                    backgroundImage: `url(${thumbnail})`,
                  }}
                  onClick={(e) => {
                    handleOpen(id, img, endDate, startDate);
                  }}
                ></div>
              ) : (
                <div className="img overflow-hidden flex items-center justify-center cursor-not-allowed">
                  <span className="z-10 text-lg text-gray-900 font-bold">
                    No Image
                  </span>
                </div>
              )}
              <div className="card-wanted__image--timer text-white font-bold absolute bottom-2 z-10">
                {timeCountDown || ""}
              </div>
            </div>
            <div className="card-wanted__description text-center px-8">
              {get(value, "description")}
            </div>
          </div>
          <div className="card-reward flex flex-col items-center justify-center">
            <div className="card-reward__title font-bold">Bounty Reward</div>
            <div className="card-reward__list flex flex-wrap justify-center items-center px-6 gap-2">
              {map(value?.rewards, (reward, index) => {
                let img = reward?.reward?.data?.attributes?.image?.data?.attributes?.url;
                let type = reward?.reward?.data?.attributes.name;

                return (
                  <div
                    className="card-reward__item flex flex-col items-center justify-center m-1 cursor-pointer"
                    key={index}
                  >
                    <div className="card-reward__image flex items-center justify-center">
                      <img src={img} alt={type} title={type} />
                      {/* {(reward.type === "TUS" && (
                        <img src={TusToken} alt={reward.type} />
                      )) ||
                        (reward.type === "CRA" && (
                          <img src={CraToken} alt={reward.type} />
                        )) ||
                        (reward.type === "CRAM" && (
                          <img src={CramToken} alt={reward.type} />
                        )) ||
                        (reward.type === "CS" && (
                          <img src={IconCS} alt={reward.type} />
                        )) ||
                        (reward.type === "EXP" && (
                          <img
                            src={IconEXP}
                            alt={reward.type}
                            style={{ width: `80%` }}
                          />
                        )) ||
                        (reward.type === "Common Item Box" && (
                          <img
                            src={IconCommonBox}
                            alt={reward.type}
                            style={{
                              width: `56px`,
                              height: `56px`,
                              top: 4,
                              borderRadius: 10,
                            }}
                          />
                        )) ||
                        (reward.type === "Rare Item Box" && (
                          <img
                            src={IconRareBox}
                            alt={reward.type}
                            style={{
                              width: `56px`,
                              height: `56px`,
                              top: 4,
                              borderRadius: 10,
                            }}
                          />
                        )) ||
                        (reward.type === "Epic Item Box" && (
                          <img
                            src={IconEpicBox}
                            alt={reward.type}
                            style={{
                              width: `56px`,
                              height: `56px`,
                              top: 4,
                              borderRadius: 10,
                            }}
                          />
                        )) ||
                        (reward.type === "Legendary Item Box" && (
                          <img
                            src={IconLegendaryBox}
                            alt={reward.type}
                            style={{
                              width: `56px`,
                              height: `56px`,
                              top: 4,
                              borderRadius: 10,
                            }}
                          />
                        ))} */}
                    </div>
                    <div className="card-reward--quantity text-2 absolute bottom-0 -mb-6">
                      x&nbsp;{reward.quantity}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
