import IconRankScore from 'assets/icons/icon_rank_score.svg';
import "./modal-arena-reward.scss";

interface Props {
  boardType: any;
  handleClose: any;
  show: any;
}

export const ModalArenaReward: React.FunctionComponent<Props> = ({
  boardType,
  handleClose,
  show
}) => {
  const cloneDB = [
    {
      id: 1,
      key: 1,
      reward: [
        {
          img: "https://materials.crabada.com/105002.png",
          total: 12,
        },
        {
          img: "https://materials.crabada.com/104005.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/310401.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/101002.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/101001.png",
          total: 8000,
        },
      ],
      rankRequired: 1,
      apRequired: 131421,
    },
    {
      id: 2,
      key: 2,
      reward: [
        {
          img: "https://materials.crabada.com/105002.png",
          total: 12,
        },
        {
          img: "https://materials.crabada.com/104005.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/310401.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/101002.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/101001.png",
          total: 8000,
        },
      ],
      rankRequired: 1,
      apRequired: 131421,
    },
    {
      id: 3,
      key: 3,
      reward: [
        {
          img: "https://materials.crabada.com/105002.png",
          total: 12,
        },
        {
          img: "https://materials.crabada.com/104005.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/310401.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/101002.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/101001.png",
          total: 8000,
        },
      ],
      rankRequired: 1,
      apRequired: 131421,
    },
    {
      id: 4,
      key: 4,
      reward: [
        {
          img: "https://materials.crabada.com/105002.png",
          total: 12,
        },
        {
          img: "https://materials.crabada.com/104005.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/310401.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/101002.png",
          total: 8000,
        },
        {
          img: "https://materials.crabada.com/101001.png",
          total: 8000,
        },
      ],
      rankRequired: 1,
      apRequired: 131421,
    },
  ]

  return (
    show && (
      <div
        // key={id}
        className={`
        flex justify-center items-center
        top-0 left-0 
        fixed w-full h-full ${show}
        transition-all
      `}
        style={{
          zIndex: 9999,
        }}
        data-aos={show ? "fade-in" : "fade-out"}
      >
        <div className="modal-main-overlay bg-black opacity-70 fixed top-0 bottom-0 left-0 right-0 transition-all"></div>

        <section
          className="modal-main relative transition-all rounded-2xl"
          data-aos="zoom-in"
        >
          {/* Close button */}
          <div className="flex w-full items-center justify-end pt-4 pr-4">
            <span
              className=" text-white relative cursor-pointer"
              onClick={handleClose}
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 12L12 36"
                  stroke="#A44747"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 12L36 36"
                  stroke="#A44747"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>

          <div className="flex flex-col items-center justify-center text-center px-4 md:px-0">
            <div className="font-bold" style={{ fontSize: 40 }}>Arena Reward By {boardType}</div>
            <div className="text-base">31/10/2022 - 6/11/2022</div>
          </div>

          {
            boardType === "Month" && (
              <div className="flex items-center justify-end px-20">
                <select className="bg-transparent text-base font-medium border-2 rounded-xl py-2 px-3" style={{ color: "#8D4247", borderColor: "#8D4247", maxHeight: 36, boxSizing: "border-box", outline: "none", boxShadow: "none" }} defaultValue={1}>
                  <option value={1}>January</option>
                  <option value={2}>February</option>
                  <option value={3}>March</option>
                  <option value={4}>April</option>
                  <option value={5}>May</option>
                  <option value={6}>June</option>
                  <option value={7}>July</option>
                  <option value={8}>August</option>
                  <option value={9}>September</option>
                  <option value={10}>October</option>
                  <option value={11}>November</option>
                  <option value={12}>December</option>
                </select>

                <select className="bg-transparent text-base font-medium border-2 rounded-xl py-2 px-3 ml-2" style={{ color: "#8D4247", borderColor: "#8D4247", maxHeight: 36, boxSizing: "border-box", outline: "none", boxShadow: "none" }} defaultValue={1}>
                  <option value={1}>2022</option>
                  <option value={2}>2023</option>
                  <option value={3}>2024</option>
                  <option value={4}>2025</option>
                  <option value={5}>2026</option>
                  <option value={6}>2027</option>
                  <option value={7}>2028</option>
                  <option value={8}>2029</option>
                  <option value={9}>2030</option>
                  <option value={10}>2031</option>
                  <option value={11}>2032</option>
                </select>
              </div>
            )
          }


          <div className="relative flex items-center mt-10">

            <div className="reward-list mx-0 md:mx-20 w-full">
              <div className="row head hidden md:grid grid-cols-4 items-center justify-between">
                <div className="header col-span-2">Rewards</div>
                <div className="header">Rank Required</div>
                <div className="header">AP Required</div>
              </div>

              <hr className="reward-list__linear border-none mt-5 hidden md:flex" />

              {
                cloneDB.map((item, index) => {
                  return (
                    <div className={`row each flex flex-col md:grid grid-cols-4 items-center justify-between px-6 md:px-0 py-4 md:py-2 ${index == 0 && "top"}`} key={item.key}>
                      <div className="flex items-center justify-between w-full md:w-auto col-span-2">
                        <div className="header flex md:hidden">Rewards</div>
                        <div className="value">
                          <div className="flex items-center">
                            {item.reward.map((j, index) => {
                              return (
                                <div key={index} className={`border-2 rounded-xl flex flex-col items-center justify-center text-center ${index !== 0 && "ml-1"}`} style={{ borderColor: '#C98E49', backgroundColor: '#FFDB8B', width: 50, height: 50 }}>
                                  <div className="">
                                    <img className=" object-contain" src={j.img} alt="" style={{ width: 28, height: 28 }} />
                                  </div>
                                  <div className="reward-list__total">{j.total}</div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <div className="header flex md:hidden">Rank Required</div>
                        <div className="value">
                          {item.rankRequired}
                        </div>
                      </div>
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <div className="header flex md:hidden">AP Required</div>
                        <div className="value">
                          <div className="flex items-center">
                            <img className="mr-1 w-6 h-6 object-contain" src={IconRankScore} />
                            <div>{item.apRequired}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <div className="reward-list__nav flex items-center justify-between -mt-8 md:mt-0 px-0 md:px-6 absolute w-full">
              <div className="btn-nav prev">
                <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.81888 0.175736C5.06037 0.410051 5.06037 0.78995 4.81888 1.02426L1.23674 4.5L4.81888 7.97574C5.06037 8.21005 5.06037 8.58995 4.81888 8.82426C4.5774 9.05858 4.18587 9.05858 3.94438 8.82426L0.362231 5.34853C-0.120745 4.8799 -0.120744 4.1201 0.362232 3.65147L3.94438 0.175736C4.18587 -0.0585787 4.5774 -0.0585787 4.81888 0.175736Z" fill="white" />
                </svg>
              </div>

              <div className="btn-nav next">
                <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.181116 8.82426C-0.0603721 8.58995 -0.0603721 8.21005 0.181116 7.97574L3.76326 4.5L0.181116 1.02426C-0.0603724 0.789949 -0.0603724 0.41005 0.181116 0.175735C0.422604 -0.0585795 0.814134 -0.0585795 1.05562 0.175735L4.63777 3.65147C5.12074 4.1201 5.12074 4.8799 4.63777 5.34853L1.05562 8.82426C0.814134 9.05858 0.422604 9.05858 0.181116 8.82426Z" fill="white" />
                </svg>
              </div>
            </div>
          </div>

        </section>
      </div>
    )
  );
};
