import { backendAPI, backendLeaderBoardAPI } from "../cores/APIHandler";
import { CMS_END_POINT } from "../resources/constants/APIRoutes";

export const getBountiesAPI = ({ page, pageSize }) =>
  backendAPI.get(
    `${CMS_END_POINT.getBounties}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=publishedAt:desc`
  );

export const getCurrentLeaderBoard = () => {
  return backendLeaderBoardAPI.get(`public/arena/ranking/current`);
};

export const getWeek = ({ week_id, email_address }) => {
  return backendLeaderBoardAPI.get(
    `public/arena/ranking/week?week_id=${week_id}${
      email_address ? `&email_address=${email_address}` : ""
    }`
  );
};

export const getMonth = ({ month_id, year_id, email_address }) => {
  return backendLeaderBoardAPI.get(
    `public/arena/ranking/month?month_id=${month_id}&year_id=${year_id}${
      email_address ? `&email_address=${email_address}` : ""
    }`
  );
};

export const getYear = ({ year_id, email_address }) => {
  return backendLeaderBoardAPI.get(
    `public/arena/ranking/year?year_id=${year_id}${
      email_address ? `&email_address=${email_address}` : ""
    }`
  );
};

export const getBannerAPI = () => backendAPI.get(`${CMS_END_POINT.getBanner}`);
