import React, { useState, useRef, useEffect } from "react";
import logo from "assets/images/logo.webp";
import logo_mobile from "assets/images/logo_footer.webp";
import '../header.scss';

const Header = () => {
  const pathName = window.location.pathname;
  const [isOpen, onToggle] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const myRef = useRef<HTMLInputElement>();
  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setNavbar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <header>
      <div className="container">
        <nav className={navbar ? "navbar" : ""}>
          <div
            onClick={() => onToggle(!isOpen)}
            className={isOpen ? "toggle-menu active" : "toggle-menu"}
          >
            <div className="toggle"></div>
          </div>
          <h1 id="logo">
            <img src={logo} alt="Crabada" className="logo-pc" />
            <img src={logo_mobile} alt="Crabada" className="logo-mb" />
          </h1>
          <ul className={isOpen ? "menu_nav active" : "menu_nav"}>
            <li>
              <a href="/" className={`${(pathName === '/home' || pathName === '/') && 'active'}`}>Home</a>
            </li>
            {/* <li>
              <a href="http://market.crabada.com">Marketplace</a>
            </li> */}
            {/* <li>
              <a href="https://market.crabada.com/staking">Staking</a>
            </li> */}
            {/* <li>
              <a href="/bounty" className={`${(pathName === '/bounty') && 'active'}`}>Bounty</a>
            </li> */}
            <li>
              <a href="/leaderboard" className={`${(pathName === '/leaderboard') && 'active'}`}>Leaderboard</a>
            </li>
            {/* <li className="sub_menu">
              <span
                ref={myRef}
                className={navbar ? "sub_menu_link active" : "sub_menu_link"}
                onClick={() => setNavbar(!navbar)}
              >
                More
              </span>
              <ul className={navbar ? "menu_list active" : "menu_list"}>
                <li>
                  <a href="https://swap.spandex.xyz/">Swap</a>
                </li>
                <li>
                  <a href="https://staking.crabada.com/lucky-draw">
                    Lucky Draw
                  </a>
                </li>
              </ul>
            </li> */}

          </ul>
        </nav>
      </div>
    </header>
  );
};
export default Header;
