import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import PageLanding from "./components/pages/page-landing/PageLanding";
import PageBounty from "./components/pages/page-bounty/PageBounty";
import PageLeaderboard from "components/pages/page-leaderboard/PageLeaderboard";

const pathName = window.location.pathname;
let trackingID = "";

const Routers = () => {
  useEffect(() => {
    trackingID = "G-J0F5RPFJF1";
    // console.log(`---> trackingID: `, trackingID);

    ReactGA.initialize(trackingID);

    ReactGA.pageview(pathName + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageLanding />} />
        <Route
          path="/bounty"
          element={
            <PageBounty title="Crabada - An Exciting Undersea Adventure Awaits🦀✨ - Bounty" />
          }
        />
        <Route
          path="/leaderboard"
          element={
            <PageLeaderboard title="Crabada - An Exciting Undersea Adventure Awaits🦀✨ - leaderboard" />
          }
        />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
